import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { BalancingItemContent } from '../BalancingItemContent';
import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IProject } from '@models/interfaces/entities/IProject';
import clsx from 'clsx';
import useStyles from './styles';

export interface IBalancingItem {
  title: string;
  type: number;
  sourceBalance: number;
  adjustments: number;
  sectionAdjustments: number;
  totalBalance: number;
  hideSource?: boolean;
  hideSectionAdjustments?: boolean;
  items?: IBalancingItem[];
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  main?: boolean;
  item: IBalancingItem;
}

export const BalancingItem = ({ project, accounts, main, item }: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onToggleExpanded = () => {
    setExpanded((val) => !val);
  };

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const balanceRecords = [
    {
      label: 'Source Balance',
      amount: item.hideSource ? 'N/A' : getBalanceLabel(item.sourceBalance),
      class: 'blue',
    },
    {
      label: 'Line Item Adjustments',
      amount: getBalanceLabel(item.adjustments),
      class: 'blue',
    },
    {
      label: 'Section Adjustments',
      amount: item.hideSectionAdjustments ? 'N/A' : getBalanceLabel(item.sectionAdjustments),
      class: 'blue',
    },
    {
      label: 'Total Balance',
      amount: getBalanceLabel(item.totalBalance),
      class: 'green',
    },
  ];

  return (
    <Box className={clsx([classes.root, (main || !expanded) && 'shadowed'])}>
      <Box className={classes.header}>
        <Typography variant='h6'>{item.title}</Typography>
        <Box className={classes.balancesContainer}>
          {balanceRecords.map((balance) => (
            <Box className={classes.balanceRecord} key={balance.label}>
              <Typography variant='subtitle2'>{balance.label}</Typography>
              <Box className={clsx([classes.balanceCount, balance.class])}>
                <Typography variant='subtitle2'>{balance.amount}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <IconButton onClick={onToggleExpanded} className={classes.expandIcon}>
          {expanded ? (
            <img alt='arrow up' src={ChevronUpIcon} />
          ) : (
            <img alt='arrow down' src={ChevronDownIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {item.items?.length ? (
          <Box className={classes.itemsListContent}>
            {item.items.map((item) => (
              <BalancingItem key={item.title} project={project} accounts={accounts} item={item} />
            ))}
          </Box>
        ) : (
          <Box className={classes.itemContent}>
            <BalancingItemContent type={item.type} project={project} accounts={accounts} />
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
