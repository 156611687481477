import { BalancingItem, IBalancingItem } from './components/BalancingItem';
import { getAccounts, getTotalAdjustments } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITotalAdjustment } from '@models/interfaces/entities/ITotalAdjustment';
import { Loader } from '@components/Loader';
import useStyles from './styles';

interface IProps {
  project: IProject;
}

interface IBalancingNode {
  title: string;
  type: number;
  hideSource?: boolean;
  hideSectionAdjustments?: boolean;
  items?: IBalancingNode[];
}

const assets: IBalancingNode = {
  title: 'Assets',
  type: -1,
  items: [
    {
      title: 'Loans',
      type: 0,
    },
    {
      title: 'Allowance for Loan Loss',
      type: 91,
      hideSource: true,
      hideSectionAdjustments: true,
    },
    {
      title: 'Investments',
      type: 1,
    },
    {
      title: 'Non-Interest Assets',
      type: 2,
      hideSource: true,
      hideSectionAdjustments: true,
    },
  ],
};

const liabilitiesAndNetWorth: IBalancingNode = {
  title: 'Liabilities and Net Worth',
  type: -1,
  items: [
    {
      title: 'Deposits',
      type: -1,
      items: [
        {
          title: 'Non-Maturity Deposits',
          type: 5,
        },
        {
          title: 'Term Deposits',
          type: 9,
        },
      ],
    },
    {
      title: 'Borrowings',
      type: 4,
    },
    {
      title: 'Non-Int Liabilities',
      type: 3,
      hideSource: true,
      hideSectionAdjustments: true,
    },
    {
      title: 'Net Worth',
      type: 92,
      hideSource: true,
      hideSectionAdjustments: true,
    },
  ],
};

export const BalancingTab = ({ project }: IProps) => {
  const { classes } = useStyles();

  const [accounts, setAccounts] = useState<IAccount[]>([]);

  const [totalAdjustments, setTotalAdjustments] = useState<ITotalAdjustment[]>([]);

  const {
    request: getAccountsRequest,
    data: getAccountsData,
    loading: getAccountsLoading,
  } = useApi(getAccounts, null, { handleErrors: true });

  const {
    request: getTotalAdjustmentsRequest,
    data: getTotalAdjustmentsData,
    loading: getTotalAdjustmentsLoading,
  } = useApi(getTotalAdjustments, null, { handleErrors: true });

  useEffect(() => {
    if (project.links[Actions.getAccounts]) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
    if (project.links[Actions.getTotalAdjustments]) {
      getTotalAdjustmentsRequest(project.links[Actions.getTotalAdjustments].href);
    }
  }, [project.links[Actions.getAccounts]]);

  useUpdateEffect(() => {
    if (getAccountsData) {
      setAccounts(getAccountsData.items);
    }
  }, [getAccountsData]);

  useUpdateEffect(() => {
    if (getTotalAdjustmentsData) {
      setTotalAdjustments(getTotalAdjustmentsData);
    }
  }, [getTotalAdjustmentsData]);

  const calculateAmounts = useCallback((type: number, totalAdjustments: ITotalAdjustment[]) => {
    const sourceBalance =
      totalAdjustments.find((adj) => adj.targetType === type && adj.operation === 'initial')
        ?.amount || 0;

    const adjustments = totalAdjustments
      .filter(
        (adj) =>
          adj.targetType === type &&
          ['manual', 'add', 'subtract', 'match', 'fees'].includes(adj.operation),
      )
      .reduce((sum, adj) => sum + adj.amount, 0);

    const sectionAdjustments = 0;

    return { sourceBalance, adjustments, sectionAdjustments };
  }, []);

  const convertToBalancingItem = useCallback(
    (node: IBalancingNode, totalAdjustments: ITotalAdjustment[]): IBalancingItem => {
      if (node.type === -1 && node.items && node.items.length > 0) {
        const childItems = node.items.map((childNode: IBalancingNode) =>
          convertToBalancingItem(childNode, totalAdjustments),
        );

        const sourceBalance = childItems.reduce((sum, child) => sum + child.sourceBalance, 0);
        const adjustments = childItems.reduce((sum, child) => sum + child.adjustments, 0);
        const sectionAdjustments = childItems.reduce(
          (sum, child) => sum + child.sectionAdjustments,
          0,
        );
        const totalBalance = sourceBalance + adjustments + sectionAdjustments;

        return {
          title: node.title,
          type: node.type,
          sourceBalance,
          adjustments,
          sectionAdjustments,
          totalBalance,
          hideSource: node.hideSource,
          hideSectionAdjustments: node.hideSectionAdjustments,
          items: childItems,
        };
      } else {
        const { sourceBalance, adjustments, sectionAdjustments } = calculateAmounts(
          node.type,
          totalAdjustments,
        );
        const totalBalance = sourceBalance + adjustments + sectionAdjustments;

        return {
          title: node.title,
          type: node.type,
          sourceBalance,
          adjustments,
          sectionAdjustments,
          totalBalance,
          hideSource: node.hideSource,
          hideSectionAdjustments: node.hideSectionAdjustments,
          items: node.items
            ? node.items.map((childNode: IBalancingNode) =>
                convertToBalancingItem(childNode, totalAdjustments),
              )
            : undefined,
        };
      }
    },
    [calculateAmounts],
  );

  const assetsItem = useMemo(
    () => convertToBalancingItem(assets, totalAdjustments),
    [assets, totalAdjustments, convertToBalancingItem],
  );
  const liabilitiesAndNetWorthItem = useMemo(
    () => convertToBalancingItem(liabilitiesAndNetWorth, totalAdjustments),
    [liabilitiesAndNetWorth, totalAdjustments, convertToBalancingItem],
  );

  const showLoader = useLoader(getAccountsLoading, getTotalAdjustmentsLoading);

  return (
    <Box className={classes.root}>
      <BalancingItem project={project} item={assetsItem} main accounts={accounts} />
      <BalancingItem project={project} item={liabilitiesAndNetWorthItem} main accounts={accounts} />
      <Loader show={showLoader} />
    </Box>
  );
};
